'use client';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { FormProvider } from 'react-hook-form';
import { parseUri } from '@walletconnect/utils';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { LoadingButton } from '@/lib/io-kit/Button';
import { FormActions } from '@/components/FormActions';
import { usePathname, useRouter } from '@/lib/navigation';
import { LinkTo } from '@/lib/links';
import { Alert } from '@/lib/io-kit/Alert';
import { useFormAlert } from '@/lib/hooks/browser';
import {
  WalletConnectFormAddressFragment,
  WalletConnectFormFragment,
  useWalletConnectLoginForm,
  WalletConnectLoginFormInputs,
} from '@/features/wallet-connect/page-logic';
import { useWalletConnectState } from '@/features/wallet-connect/context';

import { VaultInfo } from './VaultInfo';
import styles from './LoginForm.module.scss';
import { CodeInput } from './CodeInput';
import { DisableContentWithNoAssets } from './empty-states';

export type WalletConnectLoginFormProps = {
  'data-testid'?: string;
  addresses: FragmentType<typeof WalletConnectFormAddressFragment>[];
  availableVaults: FragmentType<typeof WalletConnectFormFragment>[];
  vault: FragmentType<typeof WalletConnectFormFragment>;
  defaultValues: {
    address?: FragmentType<typeof WalletConnectFormAddressFragment>;
    wcCode?: string;
  };
};

export function WalletConnectLoginForm(props: WalletConnectLoginFormProps) {
  const { addresses, availableVaults: vaults, vault, defaultValues } = props;

  const { address: defaultAddress, ...restOfDefaultValues } = defaultValues;

  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading: isWeb3WalletLoading, mutations, web3Wallet } = useWalletConnectState();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  /**
   *  Set up some local form state using calculated values, derived from the default values of the form
   */
  const [currentAddress, setCurrentAddress] = useState<
    FragmentType<typeof WalletConnectFormAddressFragment> | undefined
  >(defaultAddress ?? addresses.at(0));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const methods = useWalletConnectLoginForm({
    defaultValues: {
      vaultId: unmaskVault(vault).id,
      address: currentAddress,
      wcCode: '',
      ...restOfDefaultValues,
    },
  });

  const {
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { dismiss, showAlert: isAlertEnabled } = useFormAlert(errorMessage);

  const disableBecauseNoAssets = addresses.length === 0;

  /**
   * Set up watchers to update dependant fields and local state after form values change.
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      // Change assets shown vault changes
      if (name === 'vaultId') {
        setIsLoading(true);
        const wcCode = getValues('wcCode');
        router.push(
          LinkTo.walletConnect({
            path: pathname,
            searchParams: {
              ...Object.fromEntries(searchParams.entries()),
              ...(value.vaultId && { wcVaultId: value.vaultId }),
              ...(wcCode && { wcCode }),
            },
          }),
        );
        // FIXME: page will not refresh without this
        router.refresh();
      }

      let address: WalletConnectLoginFormProps['addresses'][number] | null = null;

      // When selecting a different address, update the "from" address and trigger validation
      if (name === 'source.id' && value.source) {
        address = findAddress(addresses, value.source.id) ?? null;
      }

      if (address) {
        const { id, addressHash, balanceAsCoin, balanceUsd, asset } = unmaskAddress(address);
        setValue('source', { id, addressHash, balanceAsCoin, balanceUsd, assetId: asset.id });
        setCurrentAddress(address);
        // Trigger validation after assetId changes in order to re-verify the destination address
        trigger();
      }
    });

    return () => subscription.unsubscribe();
  }, [addresses, getValues, pathname, router, searchParams, setIsLoading, setValue, trigger, vaults, watch]);

  const onSubmit = async (data: WalletConnectLoginFormInputs) => {
    const { topic: pairingTopic } = parseUri(data.wcCode);

    const pairingExpiredListener = ({ topic }: { topic: string }) => {
      if (pairingTopic === topic) {
        setErrorMessage(t('pairingFailed'));
        mutations.closeModal();
        web3Wallet.core.pairing.events.removeListener('pairing_expire', pairingExpiredListener);
      }
    };

    web3Wallet.once('session_proposal', async () => {
      web3Wallet.core.pairing.events.removeListener('pairing_expire', pairingExpiredListener);
    });

    try {
      setIsFormSubmitted(true);

      web3Wallet.core.pairing.events.on('pairing_expire', pairingExpiredListener);

      mutations.setSettings({
        addresses: { eip155Address: data.source.addressHash },
        activeVault: { id: data.vaultId, name: unmaskVault(vault).details.name },
      });

      await web3Wallet.pair({ uri: data.wcCode });
    } catch {
      setErrorMessage(t('pairingFailed'));
    } finally {
      // Ensure the event listener is removed when no longer needed
      web3Wallet.core.pairing.events.removeListener('pairing_expire', pairingExpiredListener);

      // clean wallet connect code from url
      router.push(
        LinkTo.walletConnect({
          path: pathname,
          searchParams: {
            ...Object.fromEntries(searchParams.entries()),
            ...(data.vaultId && { wcVaultId: data.vaultId }),
          },
        }),
      );

      setIsFormSubmitted(false);
    }
  };

  return (
    <FormProvider {...methods}>
      {/* FIXME wrong position of the overlay */}
      {/* {isLoading && <LoadingOverlayPage />} */}
      <form onSubmit={handleSubmit(onSubmit)} data-testid="wallet-connect.form">
        <VaultInfo address={findAddress(addresses, getValues('source.id'))} availableVaults={vaults} vault={vault} />

        {isLoading && (
          <div className={styles.loading}>
            <Image src="/loading.svg" width={30} height={30} alt="Loading…" />
          </div>
        )}
        {!isLoading && (
          <>
            <DisableContentWithNoAssets disabled={disableBecauseNoAssets} vault={vault}>
              <CodeInput isDisabled={isLoading} />
            </DisableContentWithNoAssets>

            <div className={styles.buttons}>
              <FormActions
                alert={
                  isAlertEnabled && errorMessage ? (
                    <Alert title={errorMessage} variant="error" onDismiss={dismiss} />
                  ) : null
                }
                main={
                  <LoadingButton
                    as="button"
                    type="submit"
                    disabled={!isValid || isFormSubmitted || isLoading || isWeb3WalletLoading}
                    variant="dark"
                    data-testid="wallet-connect.form.save"
                    loading={isFormSubmitted}
                  >
                    {t('submit')}
                  </LoadingButton>
                }
              />
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}

function findAddress(addresses: WalletConnectLoginFormProps['addresses'], addressId?: string) {
  return addresses.find((address) => getAddressId(address) === addressId);
}

function getAddressId(address: FragmentType<typeof WalletConnectFormAddressFragment>): string {
  return unmaskAddress(address).id;
}

function unmaskAddress(
  address: FragmentType<typeof WalletConnectFormAddressFragment>,
): ResultOf<typeof WalletConnectFormAddressFragment> {
  return getFragmentData(WalletConnectFormAddressFragment, address);
}

function unmaskVault(
  vault: FragmentType<typeof WalletConnectFormFragment>,
): ResultOf<typeof WalletConnectFormFragment> {
  return getFragmentData(WalletConnectFormFragment, vault);
}
