import { useTranslations } from 'next-intl';
import cx from 'classnames';

import { LinkInternal } from '@/components/Link';
import { LinkTo } from '@/lib/links';
import { Button } from '@/lib/io-kit/Button';
import { FragmentType, getFragmentData } from '@/lib/gql';

import { WalletConnectFormFragment } from '../../page-logic';

import styles from './NoAssets.module.scss';

type Props = {
  vault: FragmentType<typeof WalletConnectFormFragment>;
  disabled?: boolean;
  children: React.ReactNode;
};

export function DisableContentWithNoAssets({ vault, disabled, children }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={cx({ [styles.contentDisabled]: disabled })}>{children}</div>

      {disabled ? <NoAssetsFoundCta vault={vault} /> : null}
    </div>
  );
}

function NoAssetsFoundCta({ vault: vaultParam }: { vault: Props['vault'] }) {
  const t = useTranslations('Components.WalletConnect.NoAssetsEmptyState');

  const vault = getFragmentData(WalletConnectFormFragment, vaultParam);

  return (
    <div className={styles.ctaOverlay}>
      <div className={styles.ctaBox} data-testid="wallet-connect.no-assets-box">
        <div className={styles.ctaDescription}>{t('description', { vaultName: vault.details.name })}</div>
        <div>
          <LinkInternal variant="unstyled" passHref href={LinkTo.vaultSettings({ vaultId: vault.id, tab: 'assets' })}>
            <Button as="a" variant="primary" data-testid="wallet-connect.no-assets-cta">
              {t('cta')}
            </Button>
          </LinkInternal>
        </div>
      </div>
    </div>
  );
}
