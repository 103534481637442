'use client';
import { useTranslations } from 'next-intl';
import { Fragment, useEffect, useRef, useState } from 'react';

import { Sheet, SheetContent, SheetTrigger } from '@/components/Sheet';
import { Icons } from '@/lib/io-kit/Icons';
import { IconAsset } from '@/lib/io-kit/IconAssets';
import { FragmentType, getFragmentData } from '@/lib/gql';
import { LinkExternal } from '@/components/Link';
import { useFormAlert } from '@/lib/hooks/browser';
import { Alert } from '@/lib/io-kit/Alert';
import { showDismissableAlertToast } from '@/components/Toasts';
import { WalletConnectFormAddressFragment, WalletConnectFormFragment } from '@/features/wallet-connect/page-logic';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { WALLET_CONNECT_WEBSITE } from '@/features/wallet-connect/utils';

import styles from './Tab.module.scss';
import { WalletConnectButton } from './Button';
import { WalletConnectLoginForm, WalletConnectLoginFormProps } from './LoginForm';
import { WalletConnectUpdateForm, WalletConnectUpdateFormProps } from './UpdateForm';

type OmitCommonProps<T> = Omit<T, 'defaultValues' | 'handleClose'>;

type Props = OmitCommonProps<WalletConnectLoginFormProps & WalletConnectUpdateFormProps> & {
  defaultAddress: FragmentType<typeof WalletConnectFormAddressFragment>;
  defaultWcCode?: string | null;
};

export function WalletConnectTab(props: Props) {
  const { defaultAddress, defaultWcCode, addresses, vault, ...rest } = props;

  const vaultData = getFragmentData(WalletConnectFormFragment, vault);

  const defaultValues = {
    address: defaultAddress ?? vaultData.details.visibleAssets[0],
    wcCode: defaultWcCode ?? '',
  };

  const t = useTranslations('Components.WalletConnect.WalletConnectForm');
  const tToast = useTranslations('Toasts.WalletConnectToasts.SessionDisconnected');

  const [successMessage, setSuccessMessage] = useState('');

  const { sidebar, settings, mutations } = useWalletConnectState();
  const { dismiss: dismissSuccess, showAlert: isSuccessAlertEnabled } = useFormAlert(successMessage);

  const wasUserConnected = useRef(settings.isUserConnected);

  useEffect(() => {
    if (wasUserConnected.current && !settings.isUserConnected) {
      if (settings.isUserDisconnect) {
        setSuccessMessage(t('disconnected'));
        mutations.setIsUserDisconnect(false);
      } else {
        showDismissableAlertToast(tToast('title'), tToast('description'), 'error');
      }
    }
    wasUserConnected.current = settings.isUserConnected;
  }, [settings.isUserConnected, settings.isUserDisconnect, mutations, setSuccessMessage, t, tToast]);

  useEffect(() => {
    if (settings.isUserConnected) {
      dismissSuccess();
      setSuccessMessage('');
    }
  }, [dismissSuccess, settings.isUserConnected]);

  const onDismissSuccess = () => {
    dismissSuccess();
    setSuccessMessage('');
  };

  return (
    <Sheet onOpenChange={mutations.toggleSidebar} open={sidebar.isOpen} modal={true}>
      <SheetTrigger asChild>
        <Fragment>
          <WalletConnectButton />
        </Fragment>
      </SheetTrigger>
      <SheetContent side="right">
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.iconWrapper}>
              <IconAsset.WalletConnect className={styles.icon} />
            </div>
            <div className={styles.headerText}>
              <div className={styles.title}>{t('title')}</div>
              <LinkExternal href={WALLET_CONNECT_WEBSITE} className={styles.link} variant="on-hover">
                <Icons.ExternalLinkRounded className={styles.linkIcon} />
                <span> {t('website')}</span>
              </LinkExternal>
            </div>
          </div>
          <div className={styles.description}>{t('description')}</div>
        </div>
        {settings.isUserConnected ? (
          <WalletConnectUpdateForm
            addresses={addresses}
            defaultValues={defaultValues}
            key={vaultData.id}
            vault={vault}
            {...rest}
          />
        ) : (
          <WalletConnectLoginForm
            addresses={addresses}
            defaultValues={defaultValues}
            key={vaultData.id}
            vault={vault}
            {...rest}
          />
        )}
        {isSuccessAlertEnabled && successMessage && (
          <div className={styles.alertWrapper}>
            <Alert title={successMessage} variant="success" onDismiss={onDismissSuccess} />
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
