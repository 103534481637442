'use client';
import { useTranslations } from 'next-intl';

import { IconName, Icons } from '@/lib/io-kit/Icons';
import { Tooltip } from '@/lib/io-kit/Tooltip';
import { useWalletConnectState } from '@/features/wallet-connect/context';
import { IconButton } from '@/lib/io-kit/IconButton';

import styles from './Button.module.scss';

interface WalletButtonProps {
  isLoading?: boolean;
  isConnected?: boolean;
  disabled?: boolean;
  tooltipContent?: string;
  onClick?: () => void;
  'data-testid'?: string;
}

function BaseButton({
  isLoading = false,
  isConnected = false,
  disabled = false,
  tooltipContent,
  onClick,
  'data-testid': dataTestId,
}: WalletButtonProps) {
  if (isLoading) return <Icons.Spinner spin className={styles.spinner} />;

  const iconName: IconName = isConnected ? 'WalletConnectConnected' : 'WalletConnectNotConnected';
  const button = (
    <IconButton
      as="button"
      disabled={disabled || isLoading}
      className={styles.button}
      variant="light"
      size="large"
      onClick={onClick}
      data-testid={dataTestId}
      iconName={iconName}
    />
  );

  if (tooltipContent) {
    return (
      <Tooltip side="left" content={tooltipContent}>
        {button}
      </Tooltip>
    );
  }

  return button;
}

export function WalletConnectButton() {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');
  const { settings, isLoading, mutations } = useWalletConnectState();

  return (
    <BaseButton
      isLoading={isLoading}
      isConnected={settings.isUserConnected}
      onClick={mutations.toggleSidebar}
      tooltipContent={settings.isUserConnected ? t('walletConnected') : t('walletNotConnected')}
      data-testid="wallet-connect.login-button"
    />
  );
}

export function WalletConnectButtonSkeleton() {
  return <BaseButton data-testid="wallet-connect.login-button-skeleton" isLoading />;
}

export function WalletConnectButtonDisabled() {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  return <BaseButton disabled data-testid="wallet-connect.login-button-disabled" tooltipContent={t('noVaultFound')} />;
}
