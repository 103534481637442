'use client';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useEffect, useState, useTransition } from 'react';
import { useTranslations } from 'next-intl';
import { FormProvider } from 'react-hook-form';
import { useSearchParams } from 'next/navigation';
import cx from 'classnames';
import Image from 'next/image';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { Asset, Network } from '@/lib/models';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { FormActions } from '@/components/FormActions';
import { usePathname, useRouter } from '@/lib/navigation';
import { LinkTo } from '@/lib/links';
import { useFormAlert } from '@/lib/hooks/browser';
import { Alert } from '@/lib/io-kit/Alert';
import { handleDisconnectActiveSessions, updateWeb3WalletSessions } from '@/features/wallet-connect/services';

import {
  WalletConnectFormAddressFragment,
  WalletConnectFormFragment,
  useWalletConnectUpdateForm,
  WalletConnectUpdateFormInputs,
} from '../page-logic';
import { defaultWalletConnectSettings } from '../utils';
import { useWalletConnectState } from '../context';

import { VaultInfo } from './VaultInfo';
import styles from './UpdateForm.module.scss';
import { VaultInfoWithAction } from './VaultInfoDisplay';

export type WalletConnectUpdateFormProps = {
  'data-testid'?: string;
  addresses: FragmentType<typeof WalletConnectFormAddressFragment>[];
  availableVaults: FragmentType<typeof WalletConnectFormFragment>[];
  networks: Network.Type[];
  vault: FragmentType<typeof WalletConnectFormFragment>;
  defaultValues: {
    address?: FragmentType<typeof WalletConnectFormAddressFragment>;
    wcCode?: string;
  };
};

export function WalletConnectUpdateForm(props: WalletConnectUpdateFormProps) {
  const { addresses, availableVaults: vaults, networks, vault, defaultValues } = props;

  const { address: defaultAddress, ...restOfDefaultValues } = defaultValues;

  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [wcIsFormVisible, setwcIsFormVisible] = useState(false);

  const { isLoading: isWeb3WalletLoading, mutations, settings, web3Wallet } = useWalletConnectState();
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Prioritize state network if available
  const defaultNetwork = (defaultAddress && getAddressNetwork(defaultAddress)) ?? networks.at(0);

  /**
   *  Set up some local form state using calculated values, derived from the default values of the form
   */
  const [networkAddresses, setNetworkAddresses] = useState<FragmentType<typeof WalletConnectFormAddressFragment>[]>(
    defaultNetwork ? addressesInNetwork(addresses, defaultNetwork) : [],
  );
  const [currentAddress, setCurrentAddress] = useState<
    FragmentType<typeof WalletConnectFormAddressFragment> | undefined
  >(defaultAddress ?? networkAddresses.at(0));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const url = settings?.sessions[0]?.peer?.metadata?.url ?? '';

  const methods = useWalletConnectUpdateForm({
    defaultValues: {
      vaultId: unmaskVault(vault).id,
      network: defaultNetwork,
      address: currentAddress,
      ...restOfDefaultValues,
    },
  });

  const {
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { dismiss: dismissError, showAlert: isErrorAlertEnabled } = useFormAlert(errorMessage);
  const { dismiss: dismissSuccess, showAlert: isSuccessAlertEnabled } = useFormAlert(successMessage);

  useEffect(() => {
    if (!searchParams) return;

    const wcIsFormVisibleParam = searchParams.get('wcIsFormVisible');

    if (wcIsFormVisibleParam === 'true') {
      setwcIsFormVisible(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams) return;

    const hasVaultId = searchParams.get('wcVaultId');
    if (hasVaultId) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      router.push(
        LinkTo.walletConnect({
          path: pathname,
          searchParams: { ...Object.fromEntries(searchParams.entries()), wcVaultId: settings.activeVault.id },
        }),
      );
      // FIXME: page will not refresh without this
      router.refresh();
    }
  }, [router, searchParams, pathname, settings.activeVault.id, isLoading, vault]);

  /**
   * Set up watchers to update dependant fields and local state after form values change.
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      // Change assets shown vault changes
      if (name === 'vaultId') {
        setIsLoading(true);
        startTransition(() => {
          router.push(
            LinkTo.walletConnect({
              path: pathname,
              searchParams: {
                ...Object.fromEntries(searchParams.entries()),
                ...(value.vaultId && { wcVaultId: value.vaultId }),
                ...(wcIsFormVisible && { wcIsFormVisible: String(wcIsFormVisible) }),
              },
            }),
          );
        });
      }

      let address: WalletConnectUpdateFormProps['addresses'][number] | null = null;
      if (name === 'network' && value.network) {
        const filteredAddresses = addressesInNetwork(addresses, value.network);
        setNetworkAddresses(filteredAddresses);
        address = filteredAddresses[0];
      }

      // When selecting a different address, update the "from" address and trigger validation
      if (name === 'source.id' && value.source) {
        address = findAddress(addresses, value.source.id) ?? null;
      }

      if (address) {
        const { id, addressHash, balanceAsCoin, balanceUsd, asset } = unmaskAddress(address);
        setValue('source', { id, addressHash, balanceAsCoin, balanceUsd, assetId: asset.id });
        setCurrentAddress(address);
        // Trigger validation after assetId changes in order to re-verify the destination address
        trigger();
      }
    });

    return () => subscription.unsubscribe();
  }, [addresses, wcIsFormVisible, pathname, router, searchParams, setIsLoading, setValue, trigger, vaults, watch]);

  const onSubmit = async (data: WalletConnectUpdateFormInputs) => {
    try {
      const activeChainId = `eip155:${Network.getChainId(data.network)}`;
      const eip155Address = data.source.addressHash;

      mutations.setSettings({
        addresses: { eip155Address },
        activeVault: { id: data.vaultId, name: unmaskVault(vault).details.name },
        activeChainId,
      });
      setIsFormSubmitted(true);

      // update default selected chain with activeChainId
      await updateWeb3WalletSessions(web3Wallet, activeChainId, eip155Address);
      setSuccessMessage(t('pairingUpdatedSuccess'));
    } catch {
      setErrorMessage(t('pairingUpdatedFailed'));
    } finally {
      setIsFormSubmitted(false);
      toggleFormVisibility(data.vaultId);
    }
  };

  const onCancel = () => {
    toggleFormVisibility(settings.activeVault.id);
  };

  const onLogout = async () => {
    await handleDisconnectActiveSessions(web3Wallet);
    mutations.setSettings(defaultWalletConnectSettings);
    mutations.setIsUserDisconnect(true);
  };

  const onDismissSuccess = () => {
    dismissSuccess();
    setSuccessMessage('');
  };

  const onDismissError = () => {
    dismissError();
    setErrorMessage('');
  };

  const toggleFormVisibility = (currentVaultId: string) => {
    const newWcFormVisibility = !wcIsFormVisible;
    setwcIsFormVisible(newWcFormVisibility);

    startTransition(() => {
      router.push(
        LinkTo.walletConnect({
          path: pathname,
          searchParams: {
            ...Object.fromEntries(searchParams.entries()),
            ...(currentVaultId && { wcVaultId: currentVaultId }),
            ...(newWcFormVisibility && { wcIsFormVisible: String(newWcFormVisibility) }),
          },
        }),
      );
      router.refresh();
    });
  };

  return (
    <div className={styles.card}>
      <p className={styles.status}>
        <span>{t('connectedTo')}</span>
        {url && <span className={styles.url}>{url}</span>}
      </p>
      {!wcIsFormVisible && (
        <VaultInfoWithAction
          vault={vault}
          address={findAddress(addresses, getValues('source.id'))}
          onClick={() => toggleFormVisibility(unmaskVault(vault).id)}
          isLoading={isLoading}
        />
      )}
      {wcIsFormVisible && (
        <FormProvider {...methods}>
          {/* FIXME wrong position of the overlay */}
          {/* {isPending && <LoadingOverlayPage />} */}
          <form onSubmit={handleSubmit(onSubmit)} data-testid="wallet-connect.form">
            <VaultInfo
              address={findAddress(addresses, getValues('source.id'))}
              availableVaults={vaults}
              vault={vault}
            />
            {isPending && (
              <div className={styles.loading}>
                <Image src="/loading.svg" width={30} height={30} alt="Loading…" />
              </div>
            )}
            {!isPending && (
              <>
                <div className={styles.buttons}>
                  <FormActions
                    alert={
                      isErrorAlertEnabled &&
                      errorMessage && <Alert title={errorMessage} variant="error" onDismiss={onDismissError} />
                    }
                    main={
                      <>
                        <LoadingButton
                          as="button"
                          type="submit"
                          disabled={
                            !isValid ||
                            isFormSubmitted ||
                            isLoading ||
                            isPending ||
                            isWeb3WalletLoading ||
                            searchParams.get('wcVaultId') === settings?.activeVault.id
                          }
                          variant="dark"
                          data-testid="wallet-connect.form.save"
                          loading={isFormSubmitted}
                        >
                          {t('update')}
                        </LoadingButton>
                        <Button as="button" variant="link" onClick={onCancel}>
                          {t('cancel')}
                        </Button>
                      </>
                    }
                  />
                </div>
              </>
            )}
          </form>
        </FormProvider>
      )}
      {isSuccessAlertEnabled && successMessage && (
        <div className={cx(styles.alertWrapper, wcIsFormVisible && styles.alertWrapperMargin)}>
          <Alert title={successMessage} variant="success" onDismiss={onDismissSuccess} />
        </div>
      )}
      <div className={styles.divider} />
      <div className={styles.button}>
        <Button
          onClick={onLogout}
          disabled={isLoading}
          data-testid="wallet-connect.logout-button"
          variant="danger-light"
        >
          {t('disconnect')}
        </Button>
      </div>
    </div>
  );
}

function addressesInNetwork(addresses: WalletConnectUpdateFormProps['addresses'], network: Network.Type) {
  return addresses.filter((address) => getAddressNetwork(address) === network);
}

function findAddress(addresses: WalletConnectUpdateFormProps['addresses'], addressId?: string) {
  return addresses.find((address) => getAddressId(address) === addressId);
}

function getAddressId(address: FragmentType<typeof WalletConnectFormAddressFragment>): string {
  return unmaskAddress(address).id;
}

function getAddressNetwork(address: FragmentType<typeof WalletConnectFormAddressFragment>): Network.Type | null {
  return Asset.getNetwork(unmaskAddress(address).asset);
}

function unmaskAddress(
  address: FragmentType<typeof WalletConnectFormAddressFragment>,
): ResultOf<typeof WalletConnectFormAddressFragment> {
  return getFragmentData(WalletConnectFormAddressFragment, address);
}

function unmaskVault(
  vault: FragmentType<typeof WalletConnectFormFragment>,
): ResultOf<typeof WalletConnectFormFragment> {
  return getFragmentData(WalletConnectFormFragment, vault);
}
